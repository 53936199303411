import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import Layout from "../components/Layout";
import { ProtectComponentAfterSingin } from "../components/ProtectComponents";
import LoginComponent from "../components/Login";
import StrapiImage, { STRAPI_IMAGE_SIZES } from "../components/StrapiImage";

// ====================================================

const Login = ({ pageContext, location }) => {
  const { loginPhoto, seoData, layoutProps } = pageContext;

  return (
    <ProtectComponentAfterSingin location={location}>
      <Layout seo={seoData} showCart {...layoutProps}>
        <>
          <Container fluid className="bg-whiteOFF">
            <Row>
              <Col md={4} lg={6} className="d-none d-md-flex bg-image">
                <StrapiImage
                  src={loginPhoto?.url || ""}
                  width="100%"
                  size={STRAPI_IMAGE_SIZES.LARGE}
                />
              </Col>
              <Col md={8} lg={6}>
                <div className="login d-flex align-items-center py-5">
                  <LoginComponent />
                </div>
              </Col>
            </Row>
          </Container>
        </>
      </Layout>
    </ProtectComponentAfterSingin>
  );
};

export default Login;
